let questionList = [{
  id: 1,
  question: '62岁的老人该如何买保险？是在网上买还是在线下买？',
  answer: '你好，有社保的情况下，建议投保老年综合意外险+重大疾病保险，基本就可以满足日常需求，看您在哪个奥i是电话卡大家快吃吧就是第一金卡大家阿斯顿噶',
  tags: [{ id: 1, title: '销售技巧1' }, { id: 2, title: '产品1解析' }],
}, {
  id: 2,
  question: 'ask等哈就开始讲话大声的',
  answer: 'ask等哈就开始讲话大声的ask等哈就开始讲话大声的+重大疾病保险。',
  tags: [{ id: 1, title: '销售技巧2' }, { id: 2, title: '产品2解析' }],
}, {
  id: 3,
  question: '1111111111111111111111111111111111111111',
  answer: '1111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111',
  tags: [{ id: 1, title: '销售技巧3' }, { id: 2, title: '产品3解析' }],
}, {
  id: 4,
  question: '4444444444444444444444444444444444444',
  answer: '4444444444444444444444444444444444444444444444444444444444444444444444444444444444444444444444444444444444444444444444444444444444444444444444444444444444444444444444444444444444444444444444444444444444444444444444444444444444444444444444444444444444',
  tags: [{ id: 1, title: '销售技巧4' }, { id: 2, title: '产品4解析' }],
}, {
  id: 5,
  question: '555555555555555555',
  answer: '你5555555555555555555好，55555555有社保的情况下，建议投保老年综合意外险+重大疾病保险，基本就可以满足日常需求，看您在哪个奥i是电话卡大家快吃吧就是第一金卡大家阿斯顿噶',
  tags: [{ id: 1, title: '销售技巧5' }, { id: 2, title: '产品5解析' }],
}, {
  id: 6,
  question: '销售技巧6销售技巧6销售技巧6销售技巧6销售技巧6销售技巧6销售技巧6',
  answer: '产品6解析产品6解析产品6解析产品6解析产品6解析产品6解析产品6解析产品6解析产品6解析产品6解析产品6解析+重大疾病保险。',
  tags: [{ id: 1, title: '销售技巧6' }, { id: 2, title: '产品6解析' }],
}, {
  id: 7,
  question: '销售技巧7销售技巧7销售技巧7',
  answer: 'asdkhacakjsdasascmashsdjkhkasdkhacakjsdasascmashsdjkhkasdkhacakjsdasascmashsdjkhkasdkhacakjsdasascmashsdjkhkasdkhacakjsdasascmashsdjkhk',
  tags: [{ id: 1, title: '销售技巧7' }, { id: 2, title: '产品7解析' }],
}, {
  id: 8,
  question: '销售技巧8销售技巧8销售技巧8',
  answer: '销售技巧8销售技巧8销售技巧8销售技巧产品8解析产品8解析产品8解析8销售技巧8销售技巧8销售技巧8销售技巧8销售技巧8',
  tags: [{ id: 1, title: '销售技巧8' }, { id: 2, title: '产品8解析' }],
}]

let tagsList = [{
  title: '全部类型',
  id: 0,
}, {
  title: '沟通',
  id: 1,
}, {
  title: '行业通识',
  id: 2,
}, {
  title: '产品解析',
  id: 3,
}, {
  title: '异议处理',
  id: 4,
}, {
  title: '增员',
  id: 5,
}, {
  title: '其他',
  id: 6,
},]

let tagsList2 = [{
  title: '沟通',
  id: 1,
}, {
  title: '行业通识',
  id: 2,
}, {
  title: '产品解析',
  id: 3,
}, {
  title: '异议处理',
  id: 4,
}, {
  title: '增员',
  id: 5,
}, {
  title: '其他',
  id: 6,
},]

export {
  questionList,
  tagsList,
  tagsList2
}