<template>
  <div
    class="knowledge-base-edit"
    v-if="editData"
  >
    <van-nav-bar
      class="header-nav"
      :fixed="true"
      title="编辑"
      right-text="保存"
      left-arrow
      @click-left="onClickLeft"
      @click-right="save"
    />
    <van-popup
      v-model="visible"
      class="prompt-box"
      :closeable="true"
      @close="visible=false"
      :round="true"
      :close-on-click-overlay="false"
    >
      <p class="reminder">提醒</p>
      <h3>若删除该标签，正在使用该标签的所有问答也会将其彻底移除</h3>
      <div class="bottom">
        <van-button
          class="sure"
          @click="deleteDetail"
        >确认</van-button>
        <van-button
          class="cancel"
          @click="visible=false"
        >取消</van-button>
      </div>
    </van-popup>
    <!-- 编辑q-a start -->
    <q-a-module :obj="editData"></q-a-module>
    <!-- 编辑q-a end -->

    <!-- 添加标签 start -->
    <div class="add-tag-title">
      <h3>添加标签</h3>
      <span>选择所属标签方便您,分类查找</span>
      <p
        class="manage-tag"
        @click="changeTagManageFlag"
      >
        <img
          :src="require('@/assets/images/common_icon_Administration.png')"
          class="add-qa-detail"
        >
        标签管理
      </p>
      <img
        :src="require('@/assets/images/guide-edit2.png')"
        alt=""
        class="guide2-images-back"
        v-show="guideEditTagManageFlag&&!guideEditTagFlag"
      />
      <div
        class="bubble"
        v-if="guideEditTagManageFlag&&!guideEditTagFlag"
      >
        <div class="mask"></div>
        <div class="content-frame">
          <h3>在标签管理，你可以删除、添加标签哦！</h3>
          <van-button
            class="know"
            @click="getChildrenFlag2"
          >知道了</van-button>
          <div class="trigon"></div>
        </div>
      </div>
    </div>
    <!-- 添加标签 end -->

    <!-- 标签 start -->
    <van-tabs
      v-model="activeTag"
      class="tabs-style"
      @change="changeTag"
    >
      <div
        class="bubble"
        v-if="guideEditTagFlag"
      >
        <div class="mask"></div>
        <div class="content-frame">
          <h3>为问答添加标签，方便您分类查找</h3>
          <h3>没有合适的标签可以在对应分类下创建哦！</h3>
          <van-button
            class="know"
            @click="getChildrenFlag1"
          >知道了</van-button>
          <div class="trigon"></div>
        </div>
      </div>
      <img
        :src="require('@/assets/images/guide-edit1.jpg')"
        alt=""
        class="guide1-images-back"
        v-show="guideEditTagFlag"
      />
      <van-tab
        v-for="(item) in remberTagsList"
        :key="item.id"
        :title="item.title"
      >
        <Tag
          v-for="(elem,index) in tags2List"
          :key="index"
          :title="elem.content"
          :obj="elem"
          :active2Tag="(active2Tag==elem.label_id&&active2TagOfficial==elem.official)?true:false"
          @changeActive="changeActive"
        />
        <div
          class="add-tags"
          :style="`width:${!editFlag?'2.13rem':'3.5rem'}`"
          @click="handleTagsFlag(item)"
        >
          <img
            :src="require('@/assets/images/common_icon_add_16.png')"
            v-if="!editFlag"
          />
          <van-field
            v-model="editValue"
            class="edit-value"
          />
        </div>
      </van-tab>
    </van-tabs>
    <!-- 标签 end -->

    <!-- edit input start -->
    <div
      class="edit-input-box"
      v-show="editFlag"
    >
      <van-field
        v-model="editValue"
        @blur="blurTag"
        id="saveTagId"
        maxlength="6"
      />
      <van-button
        class="send-text"
        @click="saveTag"
      >保存标签</van-button>
    </div>
    <!-- edit input end -->

    <!-- 标签管理弹窗 start -->
    <van-popup
      v-model="tagManageFlag"
      position="bottom"
      class="manage-tag-box"
      round
    >
      <h3>标签管理</h3>
      <img
        :src="require('@/assets/images/delete.png')"
        class="delete-icon"
        @click="tagManageFlag=false"
      />
      <van-tabs
        v-model="activeTag"
        @change="changeTag"
        class="tabs-style"
      >
        <van-tab
          v-for="(item) in tagsList"
          :key="item.id"
          :title="item.title"
        >
          <Tag
            v-for="(elem,index) in tags2List"
            :key="index"
            :title="elem.content"
            type="set"
            :obj="elem"
            :active2Tag="(active2Tag==elem.label_id&&active2TagOfficial==elem.official)?true:false"
            @changeActive="changeActive"
            @deleteTag="deleteTag"
          />
          <div
            class="add-tags"
            :style="`width:${!editFlag?'2.13rem':'3.5rem'}`"
            @click="handleTagsFlag(item)"
          >
            <img
              :src="require('@/assets/images/common_icon_add_16.png')"
              v-if="!editFlag"
            />
            <van-field
              v-model="editValue"
              class="edit-value"
            />
          </div>
        </van-tab>
      </van-tabs>
      <!-- <van-button
        class="sure"
        @click="saveTagManage"
      >保存修改</van-button> -->
    </van-popup>
    <!-- 标签管理弹窗 end -->

    <!-- <bubble-frame title="在标签管理，你可以删除、添加标签哦！ 没有合适的标签可以在对应分类下创建哦！" @getChildrenFlag="getChildrenFlag2" v-if="!guideEditTagFlag&&guideEditTagManageFlag" pageType="guideEditTagManageFlag"></bubble-frame> -->
  </div>
</template>

<script>
import QAModule from "./Components/QAModule.vue";
import Tag from "./Components/Tag.vue";
import { tagsList2 } from "@/utils/mock.js";
import {
  getLabelList,
  showKnowledgeBaseDetail,
  createNewLabel,
  addKnowledgeBase,
  deleteLabel,
  editknowledgeBase,
  updateConversation,
} from "@/fetch/api/knowdgebase.js";
// import BubbleFrame from '@/components/BubbleFrame';
import { mapState, mapMutations } from "vuex";
export default {
  components: {
    QAModule,
    Tag,
    // BubbleFrame
  },
  data() {
    return {
      id: this.$route.params.id,
      broker_id: localStorage.getItem("broker_id"), //用户ID
      tagsList: null, //一级标签
      activeTag: 0, //一级选中
      tags2List: null, //二级标签
      active2Tag: 0, //二级选中
      active2TagOfficial: 0, //是否官方标签
      editData: null, //编辑数据
      editFlag: false, //新增标签flag
      editValue: "", //编辑内容
      remberTagsList: null,
      tagManageFlag: false, //标签管理弹窗
      visible: false, //删除提示visible
      deleteTag2Obj: null, //删除二级具体标签
      type: this.$route.query.type, //编辑edit add新增
      collectList: null, //对话收藏数据
    };
  },
  computed: {
    ...mapState({
      guideEditTagFlag: (state) => state.user.guideEditTagFlag,
      guideEditTagManageFlag: (state) => state.user.guideEditTagManageFlag,
    }),
  },
  methods: {
    ...mapMutations(["SET_GUIDE_FLAG", "SET_CHAT_LIST"]),
    getChildrenFlag1() {
      this.SET_GUIDE_FLAG({ type: "guideEditTagFlag", data: false });
    },
    getChildrenFlag2() {
      this.SET_GUIDE_FLAG({ type: "guideEditTagManageFlag", data: false });
    },
    changeTag() {
      this.getTabLabel("changetag");
    },
    getTabLabel(type) {
      getLabelList({
        broker_id: this.broker_id,
        parent_id: this.activeTag == -1 ? 1 : this.activeTag + 1,
      }).then((res) => {
        if (res.code == 200 && res.result.data) {
          this.tags2List = res.result.data;
          if (type == "changetag" || this.activeTag == 0) {
            this.active2Tag = res.result.data[0].label_id;
            this.active2TagOfficial = res.result.data[0].official;
          }
        }
      });
    },
    // 添加+
    handleTagsFlag() {
      this.editValue = "";
      this.editFlag = true;
      this.$nextTick(() => {
        let saveInp = document.getElementById("saveTagId");
        saveInp.focus();
      });
    },
    // 保存
    saveTag() {
      if (
        this.editValue.replace(/(^s*)|(s*$)/g, "").length == 0 ||
        this.editValue.length == 0
      ) {
        this.$toast("请输入标签");
        return;
      }
      createNewLabel({
        broker_id: this.broker_id,
        parent_id: this.activeTag + 1,
        content: this.editValue,
      }).then((res) => {
        if (res.code == 200) {
          this.$toast("保存标签成功");
        } else {
          this.$toast("保存标签失败，请稍后重试");
        }
        this.getTabLabel();
        this.editValue = "";
        this.editFlag = false;
      });
    },
    // 失焦
    blurTag() {
      setTimeout(() => {
        this.editValue = "";
        this.editFlag = false;
      }, 0);
    },
    changeTagManageFlag() {
      this.tagsList = JSON.parse(JSON.stringify(this.remberTagsList));
      this.tagManageFlag = true;
    },
    deleteDetail() {
      deleteLabel({
        label_id: this.deleteTag2Obj.label_id,
      }).then((res) => {
        if (res.code == 200) {
          this.getTabLabel("changetag");
          this.visible = false;
        } else {
          this.$toast("删除失败，请稍后重试");
        }
      });
    },
    deleteTag(obj) {
      this.visible = true;
      this.deleteTag2Obj = obj;
    },
    saveTagManage() {
      this.tagManageFlag = false;
      this.remberTagsList = this.tagsList;
    },
    changeActive(obj) {
      this.active2Tag = obj.label_id;
      this.active2TagOfficial = obj.official;
    },
    save() {
      if (this.type == "add") {
        let params = {
          broker_id: this.broker_id,
          question: this.editData.question,
          answer: this.editData.answer,
          label_id: this.active2Tag,
          official: this.active2TagOfficial,
        };
        addKnowledgeBase(params).then((res) => {
          if (res.code == 200) {
            params.online_data_id = res.result.online_data_id;
            editknowledgeBase(params).then((response) => {
              if (response.code == 200) {
                this.$toast("保存成功");
                this.$router.back();
              } else {
                this.$toast("保存失败，请稍后重试");
              }
            });
          } else {
            this.$toast("保存失败，请稍后重试");
          }
        });
        return;
      }
      if (this.type == "edit") {
        let params = {
          broker_id: this.broker_id,
          question: this.editData.question,
          answer: this.editData.answer.replace(/<br>/g, "\n"),
          label_id: this.active2Tag,
          official: this.active2TagOfficial,
          online_data_id: this.id,
        };
        editknowledgeBase(params).then((response) => {
          if (response.code == 200) {
            this.$toast("保存成功");
            this.$router.back();
          } else {
            this.$toast("保存失败，请稍后重试");
          }
        });
        return;
      }
      if (this.type == "collect") {
        let params = {
          broker_id: this.broker_id,
          question: this.editData.question,
          answer: this.editData.answer.replace(/\n/g, "<br>"),
          label_id: this.active2Tag,
          official: this.active2TagOfficial,
        };
        addKnowledgeBase(params).then((res) => {
          if (res.code == 200) {
            params.online_data_id = res.result.online_data_id;
            editknowledgeBase(params).then((response) => {
              if (response.code == 200) {
                let collectParams = {
                  is_collected: 1,
                  online_data: params.online_data_id,
                  sentence_id: this.collectList[1].sentence_id,
                };
                updateConversation(collectParams).then((resultData) => {
                  if (
                    resultData.code == 200 &&
                    resultData.result &&
                    resultData.result.is_collected == 1
                  ) {
                    this.$toast("保存成功");
                    this.SET_CHAT_LIST({
                      type: "change",
                      data: {
                        sentence_id: this.collectList[1].sentence_id,
                        online_data_id: res.result.online_data_id,
                      },
                    });
                    this.$router.back();
                  } else {
                    this.$toast(`${resultData.error}`);
                  }
                });
              } else {
                this.$toast("保存失败，请稍后重试");
              }
            });
          } else {
            this.$toast("保存失败，请稍后重试");
          }
        });
        return;
      }
    },
    getKnowledgeBaseDetail() {
      showKnowledgeBaseDetail({
        online_data_id: this.id,
      }).then((res) => {
        if (res.code == 200) {
          let result = res.result;
          result.answer = result.answer.replace(/<br>/g, "\n");
          this.editData = result;
          this.activeTag = res.result.parent_id - 1;
          this.active2Tag = res.result.label_id;
          this.active2TagOfficial = res.result.official ? 1 : 0;
          this.getTabLabel();
        }
      });
    },
    onClickLeft() {
      this.$router.back(-1);
    },
  },
  beforeRouteEnter(to, from, next) {
    next();
  },
  created() {
    this.tagsList = tagsList2;
    this.remberTagsList = tagsList2;
    if (this.type == "collect") {
      let list =
        sessionStorage.getItem("collectList") &&
        JSON.parse(sessionStorage.getItem("collectList"));
      let text = list[1].content.answer.map((item) => item.text);
      this.editData = {
        online_data_id: 0,
        question: list[0].content,
        answer: text.toString().replace(/<br>/g, "\n"), // zzj 修改前 list[1].content
        official: false,
        label_id: 0,
        label: "",
        parent_id: 0,
        update_time: "",
      };
      this.collectList = list;
      this.getTabLabel("changetag");
      return;
    }
    if (this.type == "add") {
      this.editData = {
        online_data_id: 0,
        question: "",
        answer: "",
        official: false,
        label_id: 0,
        label: "",
        parent_id: 0,
        update_time: "",
      };
      this.getTabLabel("changetag");
      return;
    }
    if (this.type == "edit") {
      this.getKnowledgeBaseDetail(); //获取详情接口
    }
  },
};
</script>

<style lang="scss" scope>
.header-nav {
  height: 1.17rem;
  .van-icon {
    font-size: 0.47rem;
    color: #4a4a4a;
    font-weight: 500;
  }
  .van-nav-bar__title {
    font-size: 0.45rem;
    font-weight: 500;
    color: #000000;
  }
  .van-nav-bar__text {
    width: 2.4rem;
    height: 0.75rem;
    background: #5bcf66;
    border-radius: 0.48rem;
    font-size: 0.4rem;
    font-family: AlibabaPuHuiTiR;
    color: #ffffff;
    line-height: 0.75rem;
  }
}
.knowledge-base-edit {
  .prompt-box {
    h3 {
      margin-top: 0.3rem;
    }
    .bottom button {
      margin-top: 0.3rem;
    }
  }
  .q-a-module {
    background: #f9f9f9;
    box-shadow: none;
  }
  .bubble {
    .mask {
      position: fixed;
      left: 0;
      top: 0;
      z-index: 10000;
      width: 100%;
      height: 100%;
      background: rgba($color: #000000, $alpha: 0.4);
    }
    .content-frame {
      position: absolute;
      top: -4rem;
      left: 0;
      right: 0;
      z-index: 10001;
      margin: auto;
      width: 9.44rem;
      min-height: 2.72rem;
      background: #4479ff;
      border-radius: 0.4rem;
      padding: 0.27rem;
      margin: 0 auto;
      text-align: center;
      h3 {
        font-size: 0.43rem;
        color: #ffffff;
        margin-top: 0.2rem;
      }
      .know {
        width: 2.33rem;
        height: 0.91rem;
        background: #ffffff;
        border-radius: 0.56rem;
        font-size: 0.43rem;
        color: #4479ff;
        margin-top: 0.3rem;
      }
      .trigon {
        position: absolute;
        right: 0;
        left: 0;
        margin: auto;
        bottom: -0.4rem;
        width: 0;
        height: 0;
        border-top: 0.6rem solid #4479ff;
        border-right: 0.4rem solid transparent;
        border-left: 0.4rem solid transparent;
      }
    }
  }
  .tabs-style {
    position: relative;
    .guide1-images-back {
      position: absolute;
      left: 0%;
      top: 0;
      z-index: 100000;
      width: 100%;
    }
    .van-tabs__nav {
      background: #f1f1f1;
    }
    .van-tabs__line {
      display: none;
    }
    .van-tab--active {
      font-size: 0.45rem;
      font-weight: 500;
      color: #4e7cde;
      line-height: 0.64rem;
    }
    .van-tab__pane {
      display: flex;
      flex-wrap: wrap;
      .tags {
        margin-top: 0.29rem;
        background: #eeeeee;
        border-radius: 0.35rem;
        border: 0.03rem solid #eeeeee;
        color: #666666;
        // margin-bottom: .29rem;
      }
      .add-tags {
        position: relative;
        // width: 2.13rem;
        height: 0.72rem;
        background: rgba(238, 238, 238, 0);
        border-radius: 0.35rem;
        border: 0.03rem solid #dddddd;
        text-align: center;
        line-height: 0.9rem;
        // margin-top: .29rem;
        margin-top: 0.29rem;
        margin-left: 0.4rem;
        // display: inline-block;
        .edit-value {
          position: absolute;
          top: 0;
          left: 0;
          padding: 0;
          padding-left: 0.2rem;
          border: none;
          border-radius: 0.35rem;
          height: 100%;
          background: transparent;
          .van-field__body {
            height: 100%;
            input {
              height: 100%;
            }
          }
        }
        img {
          width: 0.75rem;
          height: 0.43rem;
        }
      }
    }
  }
  .add-tag-title {
    width: 92%;
    height: 0.6rem;
    margin: 0 auto;
    display: flex;
    align-items: center;
    margin-bottom: 0.29rem;
    position: relative;
    .guide2-images-back {
      position: absolute;
      top: -0.2rem;
      z-index: 100001;
      width: 2rem;
      right: 0.2rem;
    }
    .content-frame .trigon {
      left: unset;
      right: 0.8rem;
    }
    h3 {
      font-size: 0.43rem;
      font-weight: 500;
      color: #333333;
      line-height: 0.59rem;
      margin-right: 2.67%;
    }
    span {
      display: inline-block;
      font-size: 0.32rem;
      font-weight: 400;
      color: #999999;
      line-height: 0.45rem;
      margin-right: 9.6%;
    }
    .van-button__text {
      font-size: 0.43rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #4479ff;
    }
    p {
      position: absolute;
      right: 4%;
      font-size: 0.35rem;
      font-weight: 400;
      color: #4e7cde;
      line-height: 0.48rem;
      display: flex;
      align-items: center;
      img {
        width: 0.4rem;
        height: 0.4rem;
      }
    }
  }
  .edit-input-box {
    position: fixed;
    left: 0%;
    bottom: 0%;
    z-index: 10000;
    width: 100%;
    height: 1.47rem;
    background: #eeeeee;
    padding: 0.29rem 4%;
    .van-field {
      // width: 79.73%;
      height: 0.91rem;
      align-items: center;
      background: #ffffff;
      border-radius: 0.45rem;
      // border-radius: .45rem 0rem 0rem .45rem;
    }
    .send-text {
      position: absolute;
      right: 4%;
      top: 0.3rem;
      height: 0.91rem;
      background: #427fff;
      border-radius: 0.56rem;
      // font-size: 0.4rem;
      font-weight: 400;
      color: #ffffff;
    }
    input::-webkit-input-placeholder {
      color: #5f78cf;
      font-size: 0.4rem;
    }
    input::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #5f78cf;
      font-size: 0.4rem;
    }
    input:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #5f78cf;
      font-size: 0.4rem;
    }
    input:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #5f78cf;
      font-size: 0.4rem;
    }
  }
  .van-overlay {
    background-color: rgba(0, 0, 0, 0.4);
  }
  .manage-tag-box {
    height: 54.8%;
    h3 {
      height: 1.49rem;
      line-height: 1.49rem;
      font-size: 0.43rem;
      font-weight: 500;
      color: #333333;
      text-align: center;
    }
    .delete-icon {
      position: absolute;
      right: 0.53rem;
      top: 0.43rem;
      width: 0.4rem;
      height: 0.4rem;
    }
    .sure {
      position: absolute;
      left: 32%;
      bottom: 9%;
      width: 3.6rem;
      height: 0.91rem;
      background: #3971ff;
      border-radius: 0.21rem;
      font-size: 0.4rem;
      font-weight: 400;
      color: #ffffff;
    }
  }
  .save {
    position: absolute;
    left: 20.3%;
    bottom: 4.9%;
    width: 59.2%;
    height: 0.96rem;
    border-radius: 0.48rem;
    border: none;
    color: #ffffff;
    font-size: 0.4rem;
    font-weight: 400;
    background: #5bcf66;
  }
}
</style>
